import clsx from "clsx";
import { ArrowUpRight } from "components/Icon";
import { LinkProps, Span } from "components/Text";
import { TooltipPlacement, TooltipProps } from "components/Tooltip";
import { MenuContext } from "components/util/BasePopoverMenu";
import { everHashProp } from "EverAttribute/EverHash";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, {
    cloneElement,
    CSSProperties,
    forwardRef,
    KeyboardEventHandler,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    RefObject,
    useContext,
    useId,
    useRef,
} from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import { FFC } from "util/type";
import "./Item.scss";

export function onItemInputKeyUp(
    inputRef: RefObject<HTMLInputElement>,
    outerOnKeyUp: KeyboardEventHandler<HTMLInputElement> | undefined,
): KeyboardEventHandler<HTMLInputElement> {
    return (event) => {
        if (event.key === "Enter") {
            inputRef.current?.click();
            // This prevents popovers inside of forms from submitting the form when selecting an
            // option using "Enter". This mirrors the behavior of HTML <select> elements.
            event.stopPropagation();
        }
        outerOnKeyUp?.(event);
    };
}

export function labelTooltipContent({
    label,
    subLabel,
    rightContent,
}: {
    label?: ReactNode;
    subLabel?: ReactNode;
    rightContent?: ReactNode;
}): ReactNode {
    return label ? (
        <>
            {label && <Span>{label}</Span>}
            {typeof rightContent === "string" && (
                <> [{<Span.Italic>{rightContent}</Span.Italic>}]</>
            )}
            {subLabel && <div>{subLabel}</div>}
        </>
    ) : undefined;
}

export interface MenuItemProps {
    children: ReactNode;
    /**
     * An optional class name to add to the item.
     */
    className?: string;
    /**
     * The color for the item. Displayed as a small strip to the left of the item.
     *
     * If the item matches the following criteria:
     *     1. Is a checkbox option or unselectable option (doesn't have extra space before
     *        label text for the checkmark icon)
     *     2. Has a color strip
     *     3. It's section has a header
     * then use the {@link MenuSectionProps.colorStripExtraMargin} option to properly align
     * the header to the option content.
     */
    color?: EverColor | string;
    /**
     * If true, ellipsifies the item content if it cannot fit on a single line.
     * Display tooltip containing the full label and sub label on hover.
     */
    ellipsify?: boolean;
    /**
     * An optional callback that is applied when the root element is clicked.
     *
     * If {@link href} is provided, then this prop will be ignored.
     */
    onClick?: MouseEventHandler<HTMLDivElement>;
    /**
     * A tooltip to render on the menu item.
     */
    tooltip?: ReactElement<TooltipProps>;
    /**
     * The URL of the menu item. Clicking this menu item will direct the user to the given URL,
     * and an icon will be added to the item automatically to indicate that it is a link.
     *
     * Only one of {@link href} and {@link link} should be provided.
     */
    href?: string;
    /**
     * If {@link href} is provided, whether the link item should be disabled. Default false.
     */
    disableHref?: boolean;
    /**
     * A link to display on the right-hand side of the label content.
     *
     * Only one of {@link href} or {@link link} should be provided.
     */
    link?: ReactElement<LinkProps>;
    /**
     * The main label text content of the menu item to use for determining the EverHash.
     */
    everHashText?: string;
}

interface ItemCSSProperties extends CSSProperties {
    "--color"?: string;
}

/**
 * A reusable component for placing items into a PopoverMenu. Used as a general wrapper for any
 * content you might want to place in a PopoverMenu's children.
 *
 * Handles some styling for the component, as well as automatically displaying a tooltip if the
 * content of the Item is ellipsified.
 *
 * This component is also reused by various other components (e.g. PopoverMenu.Checkbox,
 * PopoverMenu.Option, PopoverMenu.Toggle) that are more specific PopoverMenu children. In general,
 * you will want to use those components rather than this one, except perhaps in rare, one-off
 * cases.
 */
export const Item: FFC<HTMLDivElement | HTMLAnchorElement, MenuItemProps> = forwardRef(
    (
        {
            children,
            className,
            color,
            ellipsify = false,
            tooltip,
            onClick,
            href,
            disableHref = false,
            link,
            everHashText,
        },
        forwardedRef,
    ) => {
        const tooltipTargetRef = useRef<HTMLDivElement | HTMLAnchorElement>(null);
        const ref = useCombinedRef(forwardedRef, tooltipTargetRef);
        const childrenId = useId();
        const childrenClassName = clsx("bb-popover-menu__item", className, {
            "bb-popover-menu__item--ellipsify": ellipsify,
            "bb-popover-menu__item--colored": color !== undefined,
            "bb-popover-menu__item--with-link": href || link,
        });
        const style: ItemCSSProperties = {
            "--color": color,
        };
        tooltip &&= cloneElement(tooltip, {
            id: `${childrenId}__tooltip`,
            target: tooltipTargetRef,
            showEvents: ["focusin", "mouseenter"],
            hideEvents: ["focusout", "mouseleave"],
            placement: [TooltipPlacement.LEFT, TooltipPlacement.RIGHT],
            renderOutsideParent: true,
        });
        link &&= cloneElement(link, {
            className: clsx(link.props.className, "bb-popover-menu__item-link"),
            newTab: link.props.newTab ?? true,
        });
        const menuContext = useContext(MenuContext);
        const everHash =
            menuContext.isDropdown && menuContext.isFilterable ? {} : everHashProp(everHashText);
        const sharedProps = {
            id: childrenId,
            className: childrenClassName,
            "aria-describedby": tooltip ? `${childrenId}__tooltip` : undefined,
            ref,
            style,
            ...everHash,
        };
        const sharedChildren = (
            <>
                {color && <div className={"bb-popover-menu__item-color-strip"} />}
                {children}
            </>
        );
        return (
            <>
                {href ? (
                    <a
                        {...sharedProps}
                        href={!disableHref ? href : undefined}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                    >
                        {sharedChildren}
                        <ArrowUpRight
                            className={clsx("bb-popover-menu__item-link-icon", {
                                "bb-popover-menu__item-link-icon--disabled": disableHref,
                            })}
                            size={20}
                            color={ColorTokens.TEXT_LINK}
                        />
                    </a>
                ) : (
                    <div {...sharedProps} onClick={onClick}>
                        {sharedChildren}
                        {link && <div>{link}</div>}
                    </div>
                )}
                {tooltip}
            </>
        );
    },
);
Item.displayName = "Item";
