import clsx from "clsx";
import { ButtonProps } from "components/Button";
import { H1 } from "components/Text";
import { TextFieldProps } from "components/TextInput";
import { everIdProp } from "EverAttribute/EverId";
import React, { ReactNode, ReactElement, forwardRef } from "react";
import { EverIdProp, FFC } from "util/type";

export enum SectionVariant {
    HEADER = "header",
    FOOTER = "footer",
}

export interface SectionProps extends EverIdProp {
    /**
     * Contents of the section
     */
    children?: ReactNode;
    /**
     * Is the section collapsed
     */
    collapsed?: boolean;
    /**
     * Include border at the bottom of the section
     */
    addBorder?: boolean;
    /**
     * Optional variant to indicate that this section is either a header or footer
     */
    variant?: SectionVariant;
    /**
     * Optional section heading
     */
    heading?: string;
    /**
     * Optional button to add items in section
     */
    button?: ReactElement<ButtonProps>;
    /**
     * Optional filter box to filter items
     */
    filterTextField?: ReactElement<TextFieldProps>;
    /**
     * Optional divider in the middle of the section
     */
    addDivider?: boolean;
    /**
     * Optional 16px padding at the top of the sections. Defaults to true.
     * Needed for the case when we have a section above with no border, and we don't want this extra
     * padding at the top.
     */
    addTopPadding?: boolean;
}

/**
 * Used to organize side navigation bar into sections.
 */
export const Section: FFC<HTMLDivElement, SectionProps> = forwardRef(
    (
        {
            everId,
            children,
            collapsed,
            addBorder,
            variant,
            heading,
            button,
            filterTextField,
            addDivider,
            addTopPadding = true,
        },
        ref,
    ) => {
        const sectionClasses = clsx("bb-nav-section", {
            "bb-nav-section--top-padding": addTopPadding,
            "bb-nav-section--border": addBorder,
            [`bb-nav-section--${variant}`]: variant,
        });
        return (
            <div ref={ref} className={sectionClasses} role={"group"} {...everIdProp(everId)}>
                {!collapsed && heading && (
                    <H1.Medium className={"bb-nav-section__content"}>{heading}</H1.Medium>
                )}
                {!collapsed && button && <div className={"bb-nav-section__content"}>{button}</div>}
                {addDivider && <div className={"bb-nav-section__divider"} />}
                {!collapsed && filterTextField && (
                    <div className={"bb-nav-section__content"}>{filterTextField}</div>
                )}
                {children}
            </div>
        );
    },
);
