import clsx from "clsx";
import * as Icon from "components/Icon";
import * as CommonIcon from "components/Icon/CommonIcon";
import { TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, {
    ChangeEventHandler,
    cloneElement,
    CSSProperties,
    forwardRef,
    KeyboardEventHandler,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    RefObject,
    useId,
    useRef,
} from "react";
import { EverColor } from "tokens/typescript/EverColor";
import { EverIdProp, FFC } from "util/type";
import "./Selector.scss";

export const CHECKBOX_MAIN_LABEL_CLASS = "bb-selector__main-label";
export const CHECKBOX_SUBLABEL_CLASS = "bb-selector__sublabel";
export const CHECKBOX_LABEL_RIGHT_CLASS = "bb-selector__label-right";

export enum CheckboxValue {
    TRUE = "true",
    FALSE = "false",
    INDETERMINATE = "indeterminate",
    NOTTED = "notted",
}

export interface CheckboxProps extends EverIdProp {
    /**
     * Optional content to display under the label, sub label, and error message (if present).
     */
    children?: ReactNode;
    /**
     * An optional class name to add to the root element of the component.
     */
    className?: string;
    /**
     * If true, the checkbox cannot be toggled.
     */
    disabled?: boolean;
    /**
     * If true, the checkbox is displayed in an error state.
     */
    error?: boolean;
    /**
     * Error message that appears underneath the subLabel if the error state is true.
     * The default error message is "This field is required".
     */
    errorMessage?: ReactNode;
    /**
     * If true, do not display label.
     */
    hideLabel?: boolean;
    /**
     * The checkbox's label. Required at all times for accessibility purposes.
     * If you don't want to display a label, set hideLabel = true.
     */
    label: ReactNode;
    /**
     * A ref to forward to the checkbox's <label> element.
     */
    labelRef?: RefObject<HTMLLabelElement>;
    /**
     * A tooltip to render on the checkbox. The tooltip will point to the checkbox icon, and
     * will be triggered when the label is hovered or the input is focused.
     */
    tooltip?: ReactElement<TooltipProps>;
    /**
     * The action taken when the checkbox is clicked. Note that checkboxes do not change their
     * `value` internally, so this function should probably update `value` via a hook.
     */
    onChange: ChangeEventHandler<HTMLInputElement>;
    /**
     * An optional callback to apply when a key is pressed while the input is highlighted.
     * In general, you should use {@code onChange} for state management, but this is useful if
     * you need access to the keyboard event.
     */
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
    /**
     * An optional callback to apply when the checkbox is clicked.
     * In general, you should use {@code onChange} for state management, but this is useful if
     * you need access to the mouse event.
     */
    onClick?: MouseEventHandler<HTMLInputElement>;
    /**
     * The role for the input element of the checkbox. Usually undefined.
     */
    role?: "menuitemcheckbox" | "option";
    /**
     * Optional text underneath the label.
     */
    subLabel?: ReactNode;
    /**
     * If true, the checkbox is checked.
     * If false, the checkbox is unchecked.
     * If Indeterminate, the checkbox displays a horizontal line.
     * If Notted, the checkbox displays a red cross.
     */
    value?: CheckboxValue | boolean;
    /**
     * Whether the checkbox is an item in a menu component. Default false.
     */
    isMenuCheckbox?: boolean;
    /**
     * The content to place on the right-hand side of the checkbox label. String values will be
     * automatically styled and ellipsed if necessary.
     *
     * Note: This is for use in dropdown menus only.
     */
    rightContent?: ReactNode;
    /**
     * The width of the div containing the {@link rightContent}.
     *
     * Note: This is for use in dropdown menus only.
     */
    rightContentWidth?: number;
    /**
     * A custom tooltip to render on the menu item when label content is ellipsified. Only use
     * this prop if the default ellipsification tooltip doesn't format label content properly.
     *
     * Note: This is for use in dropdown menus only.
     */
    ellipsificationTooltip?: ReactElement<TooltipProps>;
}

/**
 * A controlled checkbox component.
 * Adjusts appearance when used within a popover menu.
 */
export const Checkbox: FFC<HTMLInputElement, CheckboxProps> = forwardRef(
    (
        {
            value = false,
            label,
            labelRef: labelRefProp,
            hideLabel = false,
            subLabel,
            error = false,
            errorMessage = "This field is required",
            tooltip,
            onChange,
            onKeyUp,
            onClick,
            disabled = false,
            children,
            role,
            className,
            isMenuCheckbox = false,
            rightContent,
            rightContentWidth,
            everId,
        },
        ref,
    ) => {
        const internalRef = useRef<HTMLInputElement>(null);
        const inputRef = useCombinedRef(internalRef, ref);
        const iconWrapperRef = useRef<HTMLDivElement>(null);
        const labelRefInternal = useRef<HTMLLabelElement>(null);
        const labelRef = useCombinedRef(labelRefProp, labelRefInternal);
        const topClass = clsx("bb-selector", className, { "bb-selector--disabled": disabled });
        const inputId = useId();

        const subLabelDiv = (
            <div
                id={`${inputId}__sublabel`}
                className={clsx(CHECKBOX_SUBLABEL_CLASS, {
                    [CHECKBOX_SUBLABEL_CLASS + "--hidden"]: hideLabel,
                })}
            >
                {subLabel}
            </div>
        );

        const errorLabel = (
            <CommonIcon.ErrorTriangle
                className={"bb-selector__error-icon"}
                size={20}
                aria-hidden={true}
            >
                {errorMessage && <div id={`${inputId}__error`}>{errorMessage}</div>}
            </CommonIcon.ErrorTriangle>
        );

        tooltip &&= cloneElement(tooltip, {
            id: `${inputId}__tooltip`,
            target: iconWrapperRef,
            hoverTrigger: tooltip.props.hoverTrigger || labelRefInternal,
            focusTrigger: tooltip.props.focusTrigger || internalRef,
        });

        const describedBy = clsx({
            [`${inputId}__sublabel`]: !isMenuCheckbox && subLabel,
            [`${inputId}__tooltip`]: tooltip,
        });

        if (typeof value === "boolean") {
            value = value ? CheckboxValue.TRUE : CheckboxValue.FALSE;
        }

        let labelContent = (
            <>
                <div ref={iconWrapperRef} className={"bb-selector__icon-wrapper"}>
                    <CheckboxIcon value={value} disabled={disabled} error={error} inMenu={!!role} />
                </div>
                <div className={"bb-selector__label-content"}>
                    <div className={CHECKBOX_MAIN_LABEL_CLASS}>{label}</div>
                    {subLabel && isMenuCheckbox && subLabelDiv}
                </div>
            </>
        );

        const labelStyle = {
            "--bb-selector-labelRight-width": rightContentWidth + "px",
        } as CSSProperties;

        if (rightContent && rightContentWidth) {
            labelContent = (
                <>
                    <div className={"bb-selector__label-left"}>{labelContent}</div>
                    <div
                        className={clsx(CHECKBOX_LABEL_RIGHT_CLASS, {
                            [`${CHECKBOX_LABEL_RIGHT_CLASS}--text`]:
                                typeof rightContent === "string",
                        })}
                    >
                        {rightContent}
                    </div>
                </>
            );
        }

        return (
            <div className={topClass}>
                <div>
                    <input
                        id={inputId}
                        type={"checkbox"}
                        checked={value === CheckboxValue.TRUE || value === CheckboxValue.NOTTED}
                        aria-checked={
                            value === CheckboxValue.INDETERMINATE
                                ? "mixed"
                                : value !== CheckboxValue.FALSE
                        }
                        aria-describedby={describedBy}
                        aria-errormessage={error && errorMessage ? `${inputId}__error` : undefined}
                        aria-invalid={error}
                        role={role}
                        aria-selected={
                            role === "option" ? value !== CheckboxValue.FALSE : undefined
                        }
                        onChange={disabled ? () => {} : onChange}
                        onKeyUp={disabled ? undefined : onKeyUp}
                        onClick={disabled ? undefined : onClick}
                        // Most elements automatically get scrolled into view on focus.
                        // Here, the input element which receives focus is absolutely positioned,
                        // which messes with this behavior.
                        onFocus={() =>
                            labelRefInternal.current?.scrollIntoView({ block: "nearest" })
                        }
                        aria-disabled={disabled}
                        ref={inputRef}
                        className={"bb-selector__input"}
                    />
                    <label
                        ref={labelRef}
                        className={clsx("bb-selector__label", {
                            "bb-selector__label--hidden": hideLabel,
                        })}
                        htmlFor={inputId}
                        style={labelStyle}
                        {...everIdProp(everId)}
                    >
                        {labelContent}
                    </label>
                    {tooltip}
                    {subLabel && !isMenuCheckbox && subLabelDiv}
                </div>
                {!hideLabel && error && errorLabel}
                {!hideLabel && children}
            </div>
        );
    },
);
Checkbox.displayName = "Checkbox";

/**
 * Utility function that returns the toggled value of the supplied value.
 * @param value the value to toggle
 * @returns the toggled value
 */
export const toggleCheckboxValue: (value: CheckboxValue) => CheckboxValue = (value) => {
    return value === CheckboxValue.FALSE ? CheckboxValue.TRUE : CheckboxValue.FALSE;
};

interface CheckboxIconProps {
    value: CheckboxValue;
    disabled: boolean;
    error: boolean;
    inMenu: boolean;
}

function CheckboxIcon({
    value = CheckboxValue.FALSE,
    disabled = false,
    error = false,
    inMenu,
}: CheckboxIconProps) {
    const iconProps = {
        color: EverColor.EVERBLUE_50,
        size: 20,
        className: "bb-selector__icon",
        "aria-hidden": true,
    };
    switch (value) {
        case CheckboxValue.TRUE:
            return <Icon.CheckboxChecked {...iconProps} />;
        case CheckboxValue.FALSE:
            return (
                <Icon.CheckboxUnchecked
                    {...iconProps}
                    color={error ? EverColor.RED_40 : EverColor.PARCHMENT_60}
                    color2={disabled && !inMenu ? EverColor.PARCHMENT_30 : EverColor.WHITE}
                    className={clsx(iconProps.className, "bb-selector__icon--unselected")}
                />
            );
        case CheckboxValue.INDETERMINATE:
            return <Icon.CheckboxIndeterminate {...iconProps} />;
        case CheckboxValue.NOTTED:
            return <Icon.CheckboxNotted {...iconProps} color={EverColor.RED_40} />;
    }
}
